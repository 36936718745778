import React from 'react';
import { Fade } from 'react-slideshow-image';



const FadeCarousel = () => {
    
    function ImageList(props) {
        const fadeImages = props.fadeImages;
        const images = fadeImages.map((fadeImages) =>
            <div key={fadeImages.key} className="slider-item">
                <div style={{ backgroundImage: `url(${fadeImages.url})`}}></div>
            </div>
        );
        return (
            <Fade className="slider">{images}</Fade>
        );
    }

    const fadeImages = [
        {key: "slide1", url:"assets/images/rotators/slide1.jpg"},
        {key: "slide2", url:"assets/images/rotators/slide2.jpg"},
        {key: "slide3", url:"assets/images/rotators/slide3.jpg"},
        {key: "slide4", url:"assets/images/rotators/slide4.jpg"},
        {key: "slide5", url:"assets/images/rotators/slide5.jpg"},
        {key: "slide6", url:"assets/images/rotators/slide6.jpg"},
        {key: "slide7", url:"assets/images/rotators/slide7.jpg"},
        {key: "slide8", url:"assets/images/rotators/slide8.jpg"},
        {key: "slide9", url:"assets/images/rotators/slide9.jpg"},
        {key: "slide10", url:"assets/images/rotators/slide10.jpg"},
        {key: "slide11", url:"assets/images/rotators/slide11.jpg"},
        {key: "slide12", url:"assets/images/rotators/slide12.jpg"}
    ];

    const fadeProperties = {
        duration: 3000,
        pauseOnHover: true,
        indicators: false
    }

    return (
        <div>
            <ImageList fadeImages={fadeImages} />
        </div>
    );
};

export default FadeCarousel;