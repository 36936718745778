const dev = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "swx-project-dev",
    },
    apiGateway: {
        NAME: "dev-swx-project-api",
        REGION: "us-east-1",
        URL: "https://p7vl7bn76g.execute-api.us-east-1.amazonaws.com/dev",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_JMOcAHQY4",
        APP_CLIENT_ID: "5pcjaq4536qeq8fto6hmkrefav",
        IDENTITY_POOL_ID: "us-east-1:48b6e1f2-272c-4a44-a832-45c69855c787",
    },
    redesign: {
        REACT_PUBLIC_API_URI: "https://sofwerx.org/",
        REACT_PUBLIC_API_EVENTS: "https://events.sofwerx.org/"
    }
};

const stage = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "swx-project-stage",
    },
    apiGateway: {
        NAME: "stage-swx-project-api",
        REGION: "us-east-1",
        URL: "https://ooeodvvoji.execute-api.us-east-1.amazonaws.com/stage",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_sSGY3KZhz",
        APP_CLIENT_ID: "5o8bqd17c8phvp61sqaspmub3r",
        IDENTITY_POOL_ID: "us-east-1:f83280ed-9b99-4b15-92bf-464094c16f69",
    },
    redesign: {
        REACT_PUBLIC_API_URI: "https://sofwerx.org/",
        REACT_PUBLIC_API_EVENTS: "https://events.sofwerx.org/"
    }
};

const prod = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "swx-project-prod",
    },
    apiGateway: {
        NAME: "prod-swx-project-api",
        REGION: "us-east-1",
        URL: "https://ov61gdbf47.execute-api.us-east-1.amazonaws.com/prod",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_8oHdkWrpJ",
        APP_CLIENT_ID: "2asog08r7tonth4rr2nlnjert4",
        IDENTITY_POOL_ID: "us-east-1:f8b7292a-2899-4aa2-a97a-73ac68a10b3b",
    },
    redesign: {
        REACT_PUBLIC_API_URI: "https://sofwerx.org/",
        REACT_PUBLIC_API_EVENTS: "https://events.sofwerx.org/"
    }
};
const config = {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    // Default to dev if not set
    ...(process.env.REACT_APP_STAGE === "prod" ? prod : (process.env.REACT_APP_STAGE === "stage" ? stage : dev)),

};

export default config;

