import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
// import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
// import { LinkContainer } from "react-router-bootstrap";
// import Button from "react-bootstrap/Button";
// import "../sass/containers/Profile.scss"
import {Auth} from "aws-amplify";
import config from "../config";

export default function EditProfile() {

    // const history = useHistory();
    // const [isLoading, setIsLoading] = useState(true)
    const [initial, setInitial] = useState(true)
    const { userHasAuthenticated } = useAppContext();
    const titleText = 'USER CONTENT AREA'
    const successText = 'Profile has been updated. Click below to profile.'
    const [profileUpdated, setProfileUpdated] = useState(false)
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [initialAttributes, setInitialAttributes] = useState([])
    const [passwordFields, handlePasswordFieldChange] = useFormFields({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });
    const [attributes, handleAttributesChange] = useFormFields({
        // userName: "",
        email: "",
        organization: "",
        firstName: "",
        lastName: "",
        confirmationCode: ""
    });

    useEffect(() => {
        async function onLoad() {
            try {
                setInitial(false)
                // const { attributes } = await Auth.currentAuthenticatedUser();

                let initialAtt = await Auth.currentAuthenticatedUser();
                // console.log(attributes)

                const userInfo = await getUserInformation(initialAtt['username']);
                // console.log(userInfo)
                setInitialAttributes(userInfo)
                // loadDefaults()

                // setIsLoading(false)
            } catch (e) {
                onError(e);
            }

            // setIsLoading(false);
        }
        if (initial) {
            onLoad();
        }}, [initial] );

    function getUserInformation (username) {
          // let path = "/user?userInput=" + "hansel.rios@sofwerx.org";
          let path = "/users?userInput=" + username;

          return API.get(config.apiGateway.NAME, path);
    }

    async function Logout () {
        console.log("Logging out");
        await Auth.signOut();
        userHasAuthenticated(false);
    }

    function validateNewPasswordForm() {
        return passwordFields.oldPassword.length > 0 &&
            passwordFields.newPassword.length > 0 &&
            passwordFields.confirmNewPassword.length > 0 &&
            passwordFields.newPassword === passwordFields.confirmNewPassword &&
            passwordFields.oldPassword !== passwordFields.newPassword;
    }

    function validateConfirmationForm() {
        return attributes.confirmationCode.length > 0;
    }

    function updateFields() {

        let newfields = {
            email : attributes.email,
            organization : attributes.organization,
            firstName : attributes.firstName,
            lastName : attributes.lastName,
        }

        if(newfields["email"] === ""){
            newfields["email"] = initialAttributes.email
        }
        if(newfields["firstName"] === ""){
            newfields["firstName"] = initialAttributes.name
        }
        if(newfields["lastName"] === ""){
            newfields["lastName"] = initialAttributes.family_name
        }
        if(newfields["organization"] === ""){
            newfields["organization"] = initialAttributes["custom:organization"]
        }

        return newfields;
    }

    async function resendConfirmationCode(event) {
        event.preventDefault();
         try {
            await Auth.verifyCurrentUserAttribute("email");
         }   catch (e) {
            onError(e);
        }

    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        // setIsLoading(true);

        try {
            await Auth.verifyCurrentUserAttributeSubmit("email", attributes.confirmationCode);
            setVerifyEmail(false)
            setProfileUpdated(true);

        }   catch (e) {
            onError(e);
            // setIsLoading(false);
        }
    }

    async function handle_password_submit(event){
        event.preventDefault();
        let user;
        let error = false;

        if (passwordFields.oldPassword.length === 0 || passwordFields.newPassword.length === 0 || passwordFields.confirmNewPassword.length === 0 ){
            error = true;
            onError('Please ensure you have typed a password in each of the boxes.');
        }
        else if(validateNewPasswordForm() )
        {
            user = Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, passwordFields.oldPassword, passwordFields.newPassword);
                })
                .then(data =>{
                    console.log(data)
                })
                .catch(err => {
                    error=true
                    console.log(err)
                    onError(err)
                });
        }

        // TODO: need to throw error if one password is empty and the other one is not
        //else if ()
        // {
        //
        // }
        // console.log(error)
        // if(!error)
        //
     }

     async function handle_credential_submit(event){
         event.preventDefault();
         let user;
         let error = false;
         let result;

         if (attributes.email.length === 0 || attributes.firstName.length === 0 || attributes.lastName.length === 0 || attributes.organization.length === 0){
             error = true;
             onError('Please ensure you have filled out each of the boxes.');
         }

         let fields = updateFields()
         //Update the rest
         user = await Auth.currentAuthenticatedUser(); //need to call this here again in case password was not updated
         // const { attributes } = user;

         result = await Auth.updateUserAttributes(user, {
             'email': fields["email"],
             'custom:organization': fields.organization,
             'name': fields.firstName,
             'family_name': fields.lastName,
         });
        //  console.log(result); // SUCCESS
         // }

         if(result==="SUCCESS" && !error)
         {
             if(fields["email"] !== initialAttributes.email)
             {
                 setVerifyEmail(true)
             }
             else{
                 setProfileUpdated(true);
             }
         }
     }

    async function delete_account() {
        let path = "/users?userInput=" + initialAttributes.Username;

        let response = await API.del(config.apiGateway.NAME, path);
        // console.log(response)

        if (response === 'User Deleted')
        {
            await Logout();
        }
        else
        {
            onError("")
        }
    }

     function renderSuccessPage() {
        return (
            <Form >
                <h1>{titleText}</h1>
                <br/>
                <div>{successText} </div>
                <br/>
                <div className="block-item">
                    <div className="btns">
                        <a href="/user-profile">Profile</a>
                    </div>
                </div>
            </Form>
        );
    }

    function renderConfirmPage() {
        return (
            <Form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId="confirmationCode" size="lg">
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="tel"
                        onChange={handleAttributesChange}
                        value={attributes.confirmationCode}
                    />
                        <Form.Text muted>Please check your email for the code.</Form.Text>
                    </Form.Group>
                <br/>
                <br/>
                <div className="block-item">
                    <div className="btns" onClick={resendConfirmationCode} >
                        <p>Resend Confirmation Code</p>
                    </div>
                </div>
                <div className="btns">
                    <input
                        type="submit"
                        // isLoading={isLoading}
                        disabled={!validateNewPasswordForm()}
                        value="Verify"
                    />
                </div>
            </Form>
        );
    }

    function renderEditProfile() {
        return (
            <Form>
                <div className="wrapper wrapper_midLimit">
                    <div className="contentBlock">
                        <div className="contentBlock-hd">
                            <h2 className="hdg hgd_2">{titleText}</h2>
                        </div>
                        <div className="contentBlock-bd contentBlock-bd_push">
                            <div  className="grid mix-grid_itemEnd">
                                <div className="grid-col grid-col_5of12">
                                    <p>
                                        <span>UserName:  </span>
                                        <span className="hdg hdg_3 mix-hdg_thick">{initialAttributes.Username}</span>
                                    </p>
                                    <Form.Group controlId="oldPassword" size="lg">
                                        <Form.Label>Old Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            value={passwordFields.oldPassword}
                                            onChange={handlePasswordFieldChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="newPassword" size="lg">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            onChange={handlePasswordFieldChange}
                                            value={passwordFields.newPassword}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="confirmNewPassword" size="lg">
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            onChange={handlePasswordFieldChange}
                                            value={passwordFields.confirmNewPassword}
                                        />
                                    </Form.Group>
                                    <div className="btns" onClick={handle_password_submit} >
                                        <p>Update Password</p>
                                    </div>
                                </div>
                                <div className="grid-col grid-col_7of12">
                                    <Form.Group controlId="email" size="lg">
                                        <Form.Label>Government Email</Form.Label>
                                        <Form.Control
                                            autoFocus
                                            type="email"
                                            placeholder={initialAttributes.email}
                                            value={attributes.email}
                                            onChange={handleAttributesChange}
                                        />
                                    </Form.Group>
                                    <Form.Group size="lg" controlId="firstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={initialAttributes.name}
                                            value={attributes.firstName}
                                            onChange={handleAttributesChange}
                                        />
                                    </Form.Group>
                                    <Form.Group size="lg" controlId="lastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={initialAttributes.family_name}
                                            value={attributes.lastName}
                                            onChange={handleAttributesChange}
                                        />
                                    </Form.Group>
                                    <Form.Group size="lg" controlId="organization">
                                        <Form.Label>Organization</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={initialAttributes["custom:organization"]}
                                            value={attributes.organization}
                                            onChange={handleAttributesChange}
                                        />
                                    </Form.Group>
                                    <div className="btns" onClick={handle_credential_submit} >
                                        <p>Update User Information</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper wrapper_small">
                    <div>
                        <div className="btns" onClick={delete_account}>
                            <p>Delete Account</p>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }

    return (
        <div className="EditProfile">
            {profileUpdated ? renderSuccessPage() : (verifyEmail ? renderConfirmPage() : renderEditProfile())}
            {/*{renderSuccessPage()}*/}
        </div>
    );
}
