import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

export default function UserMenu() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const history = useHistory();

  async function checkAuth() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No user") {
        // onError(e);
        // Popup is causing app to periodically freeze/not get keyboard input
        console.log("No current user");
      }
    }
  }

  async function Logout() {
    console.log("logged out");
    await Auth.signOut();
    userHasAuthenticated(false);
    window.location.reload();
    // history.push("/login");
  }

  function IsUser() {
    console.log("true", isAuthenticated);
    return (
      <div className="subMenu">
        <div className="subMenu-item">
          <a href="/user-profile">View Profile</a>
        </div>
        <div className="subMenu-item">
          <a href="/edit-profile">Edit Profile</a>
        </div>
        <div className="subMenu-item">
          <div onClick={Logout}>
            <p>Log Out</p>
          </div>
        </div>
      </div>
    );
  }

  function NotUser() {
    console.log("false", isAuthenticated);
    return (
      <div className="subMenu">
        <div className="subMenu-item">
          <a href="/login">Login</a>
        </div>
        <div className="subMenu-item">
          <a href="/membership-registration">Register</a>
        </div>
      </div>
    );
  }

  function MenuLinks() {
    checkAuth();
    console.log(isAuthenticated);
    if (isAuthenticated) {
      return <IsUser />;
    }
    return <NotUser />;
  }

  return (
    <div className="userMenu">
      <input id="userMenu" type="checkbox" className="userMenu-control" />
      <label for="userMenu" className="userMenu-icon"></label>
      <MenuLinks />
    </div>
  );
}
