import React, {useState} from "react";
import { useAppContext } from "../libs/contextLib";
import { API, Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import Popup from "../components/Popup";
import {onError} from "../libs/errorLib";
import Carousel from '../components/FadeCarousel';
import config from "../config";

export default function LandingPage() {

    const { isAuthenticated } = useAppContext();

    const { userHasAuthenticated } = useAppContext();
    // const [isLoading, setIsLoading] = useState(false);
    const titleText = 'SIGN IN TO PROJECTS AND TECH TUESDAY USING CAC'
    const CACloginText = 'Visibility of SOFWERX Projects as well as the Tech Tuesday Schedule are limited to US Government Common Access Card (CAC) holders and DEFENSEWERX Employees only. CAC Users: To view SOFWERX protected sites, please insert your CAC and select the Sign in with CAC button below:'
    const memberLoginText = 'Government Employees who have certificates that are not accepted by our system can access protected areas of our site by creating an account (first time user) or signing in if you’ve already been approved for access.'
    const ProjectText = 'Below are links to the past as well as current projects that SOFWERX currently has on record. If you have any questions regarding any of these projects please contact us here.'
    // var togglePopup = false;
    // const popupText = 'We are sorry to see that it looks like your CAC credentials did not go through correctly, some common issues with that are: \n \n 1. You may need to select a different certificate.' +
    //     '\n 2.You may need to update your in browser certificates. \n 3.You may need to insert your CAC card. \n \n Please make sure you have inserted your card and are using the correct certificates then ' +
    //     'try to login again. \n \nIf you do not have a CAC or the authentication still isnt working please close this dialog and use our Login or Register links on the ' +
    //     'page. \n \nAlso if you feel you are having further issues please feel free to contack us with ATTN: Website Support'

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }
    const CacLogin = async() => {
        try {
            //let response = await fetch('https://api.github.com/repos/javascript-tutorial/en.javascript.info/commits');
            let response = await fetch('https://cac.sofwerx.org/validate');
            // const body = await API.get(config.apiGateway.CAC_NAME, "/validate");
            // console.log("response = " + response)

            let body = await response.status;
            // console.log("body = " + body)

            let text = await response.json(); // read response body as json

            let responses = []; //array of promises
            responses.push(response);
            // console.log("responses = " + responses)

            let bodies = []; //array of promises
            bodies.push(body);
            Promise.all(responses).then(function() {
                // console.log("responses all"); // display here
                Promise.all(bodies).then(function() {
                    // console.log("bodies all"); // display here
                    body = bodies[0];
                    // console.log( "text = " + body )
                });
            });
            try{
                // console.log("trying to use body.uname and body.pwd")
                await Auth.signIn(text.uname, text.pwd);
                // await Auth.signIn(response.uname, body.pwd);
                userHasAuthenticated(true);
            } catch (e) {
                onError(e);
            }
        }
        catch( err ) {
            setIsOpen(!isOpen)
            console.log(err);
            // $loadingSpinner.classList.remove('loading');
            // $cacErrorClick.click();
        }

    }

    function renderPageOptions() {
        return(
            <div>
                <Carousel />
                <div className="wrapper wrapper_midLimit">
                    <div className="contentBlock">
                        <div className="contentBlock-hd contentBlock-hd_wordpress">
                            <h2 className="hdg hdg_2">SOFWERX PROJECTS</h2>
                        </div>
                        <div className="contentBlock-bd contentBlock-bd_push">
                            <p>{ProjectText}</p>
                        </div>
                    </div>
                    <div className="block block_2up mix-block_gutter">
                        <div className="block-item">
                            <div className="btns">
                                <a href="/current-projects">Current Projects</a>
                            </div>
                        </div>
                        <div className="block-item">
                            <div className="btns">
                                <a href="/past-projects">Past Projects</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
    function renderLandingPage() {
        return (
            <div className="wrapper wrapper_midLimit">
                <div className="contentBlock">
                    <div className="contentBlock-hd">
                        <h2 className="hdg hdg_2">{titleText}</h2>
                    </div>
                    <div className="contentBlock-bd contentBlock-bd_push">
                        <p>{CACloginText}</p>
                        <p><strong>(We have been experiencing intermittent issues with CAC at this time, please try using your CAC for login, if that does not work please register with a government email address and we will grant you access as quickly as possible.)</strong></p>
                        <div className="btns btns_med">
                            <button className="cacLogin" onClick={CacLogin}>Sign in With CAC</button>
                        </div>
                        <p>{memberLoginText} </p>
                        <div className="block block_2up mix-block_gutter">
                            <div className="block-item">
                                <div className="btns">
                                    <a href="/login">User Login</a>
                                </div>
                            </div>
                            <div className="block-item">
                                <div className="btns">
                                    <a href="/membership-registration">Request Access</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {isOpen && <Popup
                        content={<>
                            <b>CAC AUTHENTICATION FAILED</b>
                            <br/><br/>
                            <p>We are sorry to see that it looks like your CAC credentials did not go through correctly, some common issues with that are:<br/><br/> 1. You may need to select a different certificate. <br/> 2.You may need to update your in browser certificates. <br/> 3.You may need to insert your CAC card. <br/><br/>Please make sure you have inserted your card and are using the correct certificates then try to login again. <br/><br/>If you do not have a CAC or the authentication still isnt working please close this dialog and use our Login or Register links on the page. <br/><br/>Also if you feel you are having further issues please feel free to contack us with ATTN: Website Support</p>
                            <br/>
                        </>}
                        handleClose={togglePopup}
                    />}
                </div>
            </div>
        );
    }

    return (
        <div className="LandingPage">
            {isAuthenticated ? renderPageOptions() : renderLandingPage()}
            {/*{renderLandingPage()}*/}
        </div>
    );

}
