/* eslint-disable no-useless-concat */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { helpfulLinks, others, common, socialMedia } from "./footer";
// import Link from "next/link";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";

function Footer() {
  // const [policies, setPolicies] = useState([]);

  // useEffect(() => {
  //   fetch(`${process.env.NEXT_PUBLIC_STRAPI_URL}/policies?populate=*`)
  //     .then((res) => res.json())
  //     .then((data) => setPolicies(data.data));
  // }, []);
  return (
    <div
      className="pt-2 text-white container-fluid text-center"
      style={{ backgroundColor: "#58595B", height: "100%" }}
    >
      <div
        className="container pb-3"
        style={{ backgroundColor: "#58595B", height: "100%" }}
      >
        <div className="row pt-3">
          <div className="col">
            <p className="fs-5 text-uppercase">Follow us at</p>
            {socialMedia.map((item, index) => (
              <a
                className="px-2"
                href={item.link}
                key={index}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{ color: "#ED6622" }}
                  height={"22"}
                  className="mb-2"
                />
              </a>
            ))}
            <p className="fs-5 text-uppercase">join ecosystem</p>
            <a
              className="nav-link"
              href={`${config.redesign.REACT_PUBLIC_API_URI}ecosystem`}
              target="_self"
              alt="SOFWERX Ecosystem"
            >
              <FontAwesomeIcon
                icon={faGlobe}
                style={{ color: "#ed6622" }}
                height={"22"}
              />
            </a>
          </div>
          <div className="col-sm">
            <p className="fs-6 text-uppercase">helpful links</p>
            {helpfulLinks.map((res) => (
              <a
                className={`${res.style}`}
                style={{ color: "#D3D7D6" }}
                href={res.link}
                key={res.title}
                target={res.target}
              >
                {res.title}
              </a>
            ))}
          </div>
          <div className="col-sm">
            <p className="fs-6 text-uppercase">legal</p>
            {common.map((res, index) => (
              <a
                className={`${res.style}`}
                style={{ color: "#D3D7D6" }}
                key={res.title}
                href={res.link}
              >
                {res.title}
              </a>
            ))}
          </div>
          <div className="col-sm">
            <p className="fs-6 text-uppercase">others</p>
            {others.map((res) => (
              <a
                className={`${res.style}`}
                style={{ color: "#D3D7D6" }}
                href={res.link}
                key={res.title}
              >
                {res.title}
              </a>
            ))}
          </div>
        </div>
        <hr />
        <div className="row align-items-center">
          <div className="col-sm">
            <p className="py-2">
              &copy; 2015 - {new Date().getFullYear()} SOFWERX, Inc. All rights
              reserved.
            </p>
          </div>
          <div className="col-sm pb-2">
            <a href={`${config.redesign.REACT_PUBLIC_API_URI}`}>
              <img
                src={
                  "https://swx-strapi-events-images.s3.amazonaws.com/sofwerx_light_f001e0771a.png"
                }
                alt="SOFWERX Logo"
                width={"75%"}
              />
            </a>
          </div>
          <div className="col-sm">
            <a href="https://defensewerx.org" target="_blank" rel="noreferrer">
              <img
                src={
                  "https://swx-strapi-events-images.s3.amazonaws.com/thumbnail_defensewerx_059ee2b526.png?2023-07-21T10:37:38.777Z"
                }
                alt="DEFENSEWERX Logo"
                width={"75%"}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
