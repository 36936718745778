import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
// import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
// import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
// import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import config from "../config";

export default function Registration() {
    const [fields, handleFieldChange] = useFormFields({
        userName: "",
        email: "",
        organization: "",
        firstName: "",
        lastName: "",
        memberLevel: "WERXFamily",
        confirmationCode: "",
        generatedCaptcha: "",
        enteredCaptcha: "",
    });
    // const history = useHistory();
    const [newUser, setNewUser] = useState(null);
    // const { userHasAuthenticated } = useAppContext();
    // const [isLoading, setIsLoading] = useState(false);
    const [initial, setInitial] = useState(true);
    const [CurrentCaptcha, setCurrentCaptcha] = useState();
    const intructionsText = 'For initial requests - Please fill out the form below to register for user content access. Once approved, you will be notified with a follow up email. Please contact our support team id you have any questions or concerns, and please read our terms and privacy page per concerns with use.';
    const confirmText = 'Registration is complete. We will review your submission and send a confirmation email once your access has been approved. This process may take up to 48 hours, however we will try to get back to you as soon as possible. Some email providers may filter out the email response so if you do not receive one in a timely manner please be sure to check your spam/junk folders as well.';
    const loginText = 'Received your confirmation email and ready to login? Click the button below:';
    const [joinEcoSwitch, setJoinEcoSwitch] = useState(false);

    const onSwitchAction = () => {
        setJoinEcoSwitch(!joinEcoSwitch);
    };
    function validateForm() {
        return(
            fields.userName.length > 0 &&
            fields.email.length > 0 &&
            fields.organization.length > 0 &&
            fields.firstName.length > 0 &&
            fields.lastName.length > 0
        );
    }

    function validateUsername() {
        return(
            fields.userName.indexOf("@") <= -1
        );
    }

    function checkForSpaces(s) {
        return (
            s.indexOf(' ') <= -1
        );
    }

    function userExist(email){
        let path = "/users/check_user?userInput=" + email;
        // console.log(path)

        return API.get(config.apiGateway.NAME, path);
    }

    function setParameters(filledForm){
        let params = {
            Username : filledForm.userName,
            GovernmentEmail : filledForm.email,
            Organization : filledForm.organization,
            FirstName : filledForm.firstName,
            LastName : filledForm.lastName,
            MemberLevel : filledForm.memberLevel,
            JoinEcosystem : joinEcoSwitch,
        };
        return params;
    }

    function sendUserRequestEmail(filledForm) {
        return API.post(config.apiGateway.NAME, "/users/user_requests", { body: filledForm});
    }

    function createCaptcha() {
        //clear the contents of captcha div first
        document.getElementById('captcha').innerHTML = "";
        var charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
        var lengthOtp = 6;
        var captcha = [];
        for (var i = 0; i < lengthOtp; i++) {
            //below code will not allow Repetition of Characters
            var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
            if (captcha.indexOf(charsArray[index]) === -1)
                captcha.push(charsArray[index]);
            else i--;
        }

        var canv = document.createElement("canvas");
        canv.id = "captcha";
        canv.width = 100;
        canv.height = 50;
        var ctx = canv.getContext("2d");
        ctx.font = "25px Georgia";
        ctx.strokeStyle = '#28282A';
        ctx.strokeText(captcha.join(""), 0, 30);
        //storing captcha so that can validate you can save it somewhere else according to your specific requirements
        var code = captcha.join("");
        document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
        setCurrentCaptcha(code);
        // console.log(code)
    }

    function validateCaptcha() {
        if (document.getElementById("captchaTextBox").value === CurrentCaptcha) {
            // alert("Valid Captcha")
            return true;
        }else{
            alert("Invalid Captcha. try Again");
            createCaptcha();
            return false;

        }
    }

    useEffect(()=>{
        if (initial){
            createCaptcha()
            setInitial(false);
        }
    },[initial])

    async function handleSubmit(event) {
        event.preventDefault();
        // setIsLoading(true);
        if (!validateForm()) {
            onError("Fields cannot be empty")
            return
            // return setIsLoading(false)
        }

        if(!validateUsername()) {
            onError("Your username cannot be an email address. Please enter a different username.")
            return
        }

        //Username
        if(!checkForSpaces(fields.userName)){
            onError("Your username cannot have spaces.")
            return
        }

        //Email
        if(!checkForSpaces(fields.email)){
            onError("Your email cannot have spaces.")
            return
        }

        // TODO: Identify/show if a user request exist. This happens on api side but client just gets generic 500 error.
        // var params = setParameters(fields)
        let existingUser =  await userExist(fields.email)
        if (!existingUser.UserExists && !existingUser.RequestExists){
            try {
                // setIsLoading(false);
                const newUser = 1;
                setNewUser(newUser);

                var params = setParameters(fields)
                // console.log("Sending email with: " + JSON.stringify(params, null, 4))
                await sendUserRequestEmail(params)
                // console.log(registered)
            } catch (e) {
                onError(e);
                // setIsLoading(false);
                // createCaptcha();
            }

        }
        else{
            onError("User or request exist for the entered email")
        }

    }

function renderEmailConfirm() {
        return (
            <div className="wrapper">
                <div className="contentBlock">
                    <div className="contentBlock-hd">
                        <h2>User Registration</h2>
                    </div>
                    <div className="contentBlock-bd contentBlock-bd_push">
                        <div>{confirmText}</div>
                        <br/><br/>
                        <div>{loginText}</div>
                        <div className="block-item">
                            <div className="btns">
                                <a href="/login">User Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function renderForm() {
        return (
            <div className="wrapper">
                <div className="contentBlock">
                    <div className="contentBlock-hd">
                        <h1>User Registration</h1>
                    </div>
                    <div className="contentBlock-bd contentBlock-bd_push">
                        <p>{intructionsText}</p>
                    </div>
                </div>
                <Form onSubmit={handleSubmit} className="form form_sm">
                    <Form.Group controlId="userName" size="lg">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.userName}
                            onChange={handleFieldChange}
                            placeholder="username"
                        />
                    </Form.Group>
                    <Form.Group controlId="email" size="lg">
                        <Form.Label>Government Email</Form.Label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={fields.email}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="organization">
                        <Form.Label>Name of Organization</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.organization}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.firstName}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.lastName}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group size="lg" controlId="membershipLevel">
                            <Form.Label>Membership Level</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.memberLevel}
                                onChange={handleFieldChange}
                                placeholder="WERXFamily"
                                disabled={true}
                            />
                    </Form.Group>
                    <p>SOFWERX is committed to protecting and respecting your privacy , and we'll only use your personal information to inform you of upcoming opportunities from USSOCOM. If you consent to us contacting you for this purpose, please confirm below. </p>
                    <Form.Group size="lg" controlId='swxEco'>
                            <Form.Check
                                onChange={onSwitchAction}
                                id="eco-switch"
                                label="I agree to receive other communications from SOFWERX"
                                checked={joinEcoSwitch}

                            />
                    </Form.Group>
                    <p>You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our <a href="https://sofwerx.org/legal" target="_self" style={{textDecoration: "underline #ed6622"}}>Privacy Policy</a></p>
                    <p>By clicking submit below, you consent to allow SOFWERX to store and process the personal information submitted above to provide you the content requested</p>
                    <div className="form-captcha">
                        <div id="captcha" styles="text-align:center;"></div>
                        <input type="text" placeholder="Captcha" id="captchaTextBox"/>
                        <div className="btns mix-btns_drk">
                            <input type="button" onClick={createCaptcha} value="Reload Captcha" />
                        </div>
                    </div>
                    <div className="block-item">
                        <div className="btns">
                            <input type="submit" onClick={handleSubmit} value="Register" />
                        </div>
                    </div>
                </Form>
            </div>
        );
    }

    return (
        <div className="Registration">
            {newUser === null ? renderForm() : renderEmailConfirm()}
        </div>
    );

}
