import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import ListGroup from "react-bootstrap/ListGroup";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import Carousel from "../components/FadeCarousel";
import config from "../config";

export default function CurrentProjects() {
  const [cpos, setCPOs] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      /*      if (!isAuthenticated) {
        return;
      }
*/
      try {
        const cpos = await loadCPOs();
        setCPOs(cpos);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadCPOs() {
    return API.get(config.apiGateway.NAME, "/cpos");
  }

  function renderCPOsList(cpos) {
    // TODO: Look into utilizing correct array-callback-return for this funciton, throwing a warning and needs to be refactored properly.
    // eslint-disable-next-line
    return cpos.map((cpo) => {
      const CPOProjectLength = cpo.Projects.length;

      if (CPOProjectLength > 0) {
        return (
          <>
            <div key={cpo.Name} className="cell">
              <div className="cell-int cell-int_sm mix-cell-int_label">
                <span>{cpo.Name}</span>
              </div>
              <div className="cell-int cell-int_bd">
                {cpo.Projects.map((project, index) => {
                  return (
                    <>
                      <div
                        key={project.Name}
                        className="cell cell_int"
                        data-status={project.Status}
                      >
                        <div className="cell-int cell-int_md">
                          <span>{project.Name}</span>
                        </div>
                        <div className="cell-int cell-int_lg mix-cell-int_txtL">
                          <p>{project.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        );
      }
    });
  }

  function renderCPOs() {
    return (
      <div className="notes">
        <div className="cell mix-cell_top">
          <div className="cell-int cell-int_sm mix-cell-int_label">
            <span className="hdg hdg_3">CPO</span>
          </div>
          <div className="cell-int cell-int_bd">
            <div className="cell cell_int">
              <div className="cell-int cell-int_md">
                <span className="hdg hdg_3">Project</span>
              </div>
              <div className="cell-int cell-int_lg">
                <span className="hdg hdg_3">Description</span>
              </div>
            </div>
          </div>
        </div>
        <ListGroup>{!isLoading && renderCPOsList(cpos)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="ActiveCPOs">
      <Carousel />
      <div className="wrapper">
        <div className="grid">
          <div className="grid-col grid-col_3of12">
            <div className="btns">
              <a href="/">Project Home</a>
            </div>
          </div>
          <div className="grid-col grid-col_6of12"></div>
          <div className="grid-col grid-col_3of12">
            <div className="btns">
              <a href="/past-projects">Past Projects</a>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper wrapper_midLimit">
        <div className="contentBlock">
          <div className="contentBlock-hd contentBlock-hd_wordpress">
            <h2 className="hdg hdg_2">Current Projects</h2>
          </div>
          <div className="contentBlock-bd contentBlock-bd_push">
            <p>
              SOFWERX helps to develop prototypes and proofs of concept that
              support the USSOCOM warfighter needs. The data below is a list of
              the projects we are currently undertaking. To receive additional
              information on a specific project, please contact us here.
            </p>
          </div>
        </div>
      </div>
      <div className="wrapper">{renderCPOs()}</div>
    </div>
  );

  /*
      <div className="ActiveCPOs">
        {isAuthenticated ? renderCPOs() : renderLander()}
    </div>
   */
}
