import React from "react";
import {ReactComponent as DWXLogo} from "../assets/images/svg/inline-defensewerx_linear_flat.svg";
import {ReactComponent as SWXShort} from "../assets/images/svg/inline-swx_short.svg";
import {ReactComponent as FBIcon} from "../assets/images/socicon/soc-fb.svg";
import {ReactComponent as IGIcon} from "../assets/images/socicon/soc-ig.svg";
import {ReactComponent as INIcon} from "../assets/images/socicon/soc-in.svg";
import {ReactComponent as TWIcon} from "../assets/images/socicon/soc-tw.svg";
import {ReactComponent as Xicon} from "../assets/images/socicon/soc-x.svg";
import {ReactComponent as YTIcon} from "../assets/images/socicon/soc-yt.svg";

const SiteFooter = () => (
    <div id="colophon" className="footer">
		<div className="footer-bd">
			<div className="footer-bd-bg">
                <SWXShort/>
			</div>
			<div className="wrapper">
				<div className="grid mix-grid_gapLg">
					<div className="grid-col grid-col_4of12">
						<div className="list list_vert mix-list_gapMd">
							<div>
								<a className="branding branding_light" href="https://defensewerx.org/" rel="noreferrer" target="_blank">
                                    <DWXLogo/>
								</a>
							</div>
							<div>
								<div className="list list_vert">
									<a className="link link_clean mix-link_white mix-link_invert" href="tel:8136935599">
										<span className="link-label">Phone: </span>
										<span>813.693.5599</span>
									</a>
									<a className="link link_clean mix-link_white mix-link_invert" href="mailto:frontofhouse@sofwerx.org">
										<span className="link-label">Email: </span>
										<span>frontofhouse@sofwerx.org</span>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="grid-col grid-col_8of12">
						<div className="contentBlock contentBlock_algRight">
							<div className="contentBlock-hd mix-contentBlock-hd_label">
								<h2 className="hdg hdg_3 mix-hdg_subtle">Notice for all interested parties to SOFWERX events:</h2>
							</div>
							<div className="contentBlock-bd mix-contentBlock-bd_brand mix-contentBlock-bd_clean">
								<p>Announcement of SOFWERX events that are considered to have high potential for further efforts that may be accomplished via FAR-based contracting instruments, Other Transaction Authority (OTA) for Research Projects, 10 USC 4021 and Prototype Projects 10 USC 4022, Prizes for advanced technology achievements 10 USC 4025, and/or Prize Competitions 15 USC 3719, may be made at the <a class="link mix-link_invert" href="https://www.sofwerx.org">www.sofwerx.org</a> and/or <a class="link mix-link_invert" href="https://www.teamwerx.org" target="_blank" rel="noreferrer">www.teamwerx.org</a> website(s). All announcements made at the <a class="link mix-link_invert" href="https://www.sofwerx.org">www.sofwerx.org</a> and/or <a class="link mix-link_invert" href="https://www.teamwerx.org" target="_blank" rel="noreferrer">www.teamwerx.org</a> website(s) are considered to satisfy the reasonable effort to obtain competition in accordance with 10 USC 4025, 15 USC 3719 (e) and 10 USC 4022. All FAR-based actions will follow announcement procedures per FAR 5.201(b) accordingly.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="footer-base">
			<div className="wrapper">
				<div className="grid mix-grid_gapSm">
					<div className="grid-col grid-col_3of12">
						<div className="legal">
							<span className="legal-cr">{(new Date().getFullYear())}  © Copyrights SOFWERX</span>
							<a className="legal-link" href="/legal/">Privacy Policy</a>
						</div>
					</div>
					<div className="grid-col grid-col_7of12">
						<div className="sitemap">
							<a href="https://sofwerx.org/legal/">Legal</a>
							<a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&amp;business=billing@sofwerx.org&amp;lc=US&amp;item_name=Doolittle+Institute/SOFWERX+office+OpenWERX&amp;no_note=0&amp;cn=&amp;curency_code=USD&amp;bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHosted" target="_blank" rel="noreferrer">
								Donate
							</a>
							<a href="https://sofwerx.org/about/">FAQ's</a>
							<a href="https://sofwerx.org/welcome/">Contact Us</a>
                            <a href="https://sofwerxswag.square.site/">Merch</a>
						</div>
					</div>
					<div className="grid-col grid-col_2of12">
						<div className="social">
							<a className="social-li" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/sofwerx"><INIcon/></a>
							<a className="social-tw" target="_blank" rel="noopener noreferrer" href="https://twitter.com/sofwerx"><Xicon/></a>
							<a className="social-ig" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/sofwerx/"><IGIcon/></a>
							<a className="social-yt" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCjg_-U81wxjsHhjRerEUwnQ"><YTIcon/></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
export default SiteFooter;