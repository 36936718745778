import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import Carousel from "../components/FadeCarousel";
import Form from "react-bootstrap/Form";
import config from "../config";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";
import LoaderButton from "../components/LoaderButton";

export default function CurrentProjects() {
    const [index, setIndex] = useState(0);
    const [allProjects, setAllProjects] = useState([])
    const [projects, setProjects] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [queryString, setQueryString] = useState("");
    const [noSearchResults, setNoSearchResults] = useState(false);
    const [sortBy, setSortBy] = useState("null");
    const [search, setSearch] = useState(false);
    const [limit, setLimit] = useState("24");

    // function validateForm() {
    //     return queryString.length > 0;
    // }
    function checkPrevSubmit(){

        if(projects[0] === allProjects[0]){
            return true;
        }else{
            return false;
        }
    }
    function checkLastKey(){
        //check if the project's length is less than the limit
        if(projects[projects.length - 2] === "null") {
            return true;
        }else{
            return false;
        }
    }

    function searchProject(input, startIndex) {
        let path = "/search_past_projects?userInput=" + input;
        path = path + "&startIndex=" + startIndex;
        path = path + "&limit=" + limit;
        path = path + "&sortBy=" + sortBy;
        // console.log(path)
        return API.get(config.apiGateway.NAME, path);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        if(queryString.length > 0) {
            try {
                setSearch(true)
                const project_set = await searchProject(queryString.toLowerCase(), "null");
                setNoSearchResults(project_set[project_set.length - 1]);
                setProjects(project_set);
                setAllProjects(project_set);
                setIsLoading(false);
            } catch (e) {
                onError("No search results have been found. Please use a different key word or refer to other projects.");
                setIsLoading(false);
            }
        }else{
            try {
                setSearch(false)
                const project_set = await loadProjects("null");
                setProjects(project_set);
                setIsLoading(false);
                setNoSearchResults(project_set[project_set.length - 1]);
                setAllProjects(project_set);
            } catch (e) {
                onError(e);
                setIsLoading(false);
            }
        }
        setIndex(0);
        setIsLoading(false);
    }

    async function handlePrevSubmit(event) {
        event.preventDefault();
        try {
            setProjects(allProjects.slice(allProjects.indexOf(projects[0]) - limit - 2, allProjects.indexOf(projects[0])));
            setIsLoading(false);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    async function handleNextSubmit(event) {
        var search2 = true;
        event.preventDefault();
        setIsLoading(true);
        if(noSearchResults){
            search2 = false;
        }

        if(allProjects.indexOf(projects[0]) === index) {
            if (!search || !search2) {
                try {
                    const project_set = await loadProjects(JSON.stringify(projects[projects.length - 2]), sortBy);
                    setAllProjects([...allProjects, ...project_set]);
                    setProjects(project_set);
                    setIndex(index + parseInt(limit) + 2);
                    setIsLoading(false);
                } catch (e) {
                    onError(e);
                    setIsLoading(false);
                }
            } else {
                try {
                    const project_set = await searchProject(queryString.toLowerCase(), JSON.stringify(projects[projects.length - 2]));
                    setAllProjects([...allProjects, ...project_set]);
                    setProjects(project_set);
                    setIndex(index + parseInt(limit) + 2);
                    setIsLoading(false);
                } catch (e) {
                    onError(e);
                    setIsLoading(false);
                }
            }
        }else{
            setProjects(allProjects.slice(allProjects.indexOf(projects[0]) + parseInt(limit) + 2, allProjects.indexOf(projects[0]) + (2*parseInt(limit)) + 4));
        }
        setIsLoading(false);
    }

  useEffect(() => {
    async function onLoad() {
/*      if (!isAuthenticated) {
        return;
      }

*/

        try {
            const project_set = await loadProjects("null");
            setProjects(project_set);
            setAllProjects(project_set);
        } catch (e) {
            onError(e);
        }

        setIsLoading(false);

    }

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  function loadProjects(startIndex) {
      let path = "/past_projects?limit=" + limit;
      path = path + "&startIndex=" + startIndex;
      path = path + "&sortBy=" + sortBy
      // console.log(path)
      let project_set = API.get(config.apiGateway.NAME, path);
      return project_set;
  }

  function renderProjectsList(projects) {
          return projects.map((project, index) => {
              if(project !== true && project !== false) {
                  return (
                      <>
                          <div className="imageBlock">
                              <a href={project.DocumentPath} id="a0" target="_blank" rel="noreferrer">
                                  <img src={project.ImagePath} alt="thumbnail"/>
                              </a>
                          </div>
                      </>
                  );
              }else{
                  return(
                      <></>
                  );
              }
          })
  }

  function renderProjects() {
      let sortedProjects = [];

      for(let i = 0; i <= projects.length-3; i++){
          sortedProjects.push(projects[i]);
      }

      if(!noSearchResults) {
          return (
              <div className="wrapper">
                  <div className="block block_3up mix-block_gutterSm">
                      {!isLoading && renderProjectsList(sortedProjects)}
                  </div>
              </div>
          );
      }else{
          return (
              <div className="wrapper">
                  <div className="contentBlock-bd contentBlock-bd_push">
                      <p>No search results</p>
                  </div>
                  <div className="block block_3up mix-block_gutterSm">
                      {!isLoading && renderProjectsList(sortedProjects)}
                  </div>
              </div>
          );
      }
  }

  return (
    <div className="Completed Projects">
        <Carousel />
        <div className="wrapper">
            <div className="grid">
                <div className="grid-col grid-col_3of12">
                    <div className="btns">
                        <a href="/">Project Home</a>
                    </div>
                </div>
                <div className="grid-col grid-col_6of12">
                </div>
                <div className="grid-col grid-col_3of12">
                    <div className="btns">
                        <a href="/current-projects">Current Projects</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="wrapper wrapper_midLimit">
            <div className="contentBlock">
                <div className="contentBlock-hd contentBlock-hd_wordpress">
                    <h2 className="hdg hdg_2">Past Projects</h2>
                </div>
                <div className="contentBlock-bd contentBlock-bd_push">
                    <p>Since inception, our team has worked on multiple projects that support the USSOCOM warfighter.  Below you will find quad charts which denote the summary, timelines, costs and results of those projects.  Capabilities that have transitioned to Programs of Record are noted at the bottom of the quads. If you have any questions regarding any of these projects please contact us here.</p>
                </div>
            </div>

            <Form  className="searchBlock" onSubmit={handleSubmit}>
                <Form.Group className="searchBlock-split" controlId="CPOname">
                    <Form.Control
                        value={queryString}
                        type="text"
                        onChange={(e) => setQueryString(e.target.value)}
                    />
                    <div className="btns">
                        <input
                            type="submit"
                            isLoading={isLoading}
                            disabled={false}
                            value="Search"
                        />
                    </div>
                </Form.Group>

                <Form.Group controlId="lifecycleState">
                    <Form.Control
                        value={sortBy}
                        as="select"
                        onChange={(e) => setSortBy(e.target.value)}
                    >
                        <option>Sort by: </option>
                        <option>Date: Old to New</option>
                        <option>Date: New to Old</option>
                        <option>Name: Alphabetically</option>
                        <option>Name: Reverse Alphabetically</option>
                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
        {renderProjects()}

        <div className="wrapper">
            <div className="grid">
                <div className="grid-col grid-col_3of12">
                    <Form inLine onSubmit={handlePrevSubmit}>
                        <div className="btns">
                            <input
                                type="submit"
                                isLoading={isLoading}
                                disabled={checkPrevSubmit()}
                                value="Prev"
                            />
                        </div>
                    </Form>
                </div>
                <div className="grid-col grid-col_6of12">
                </div>
                <div className="grid-col grid-col_3of12">
                    <Form inLine onSubmit={handleNextSubmit}>
                        <div className="btns">
                            <input
                                type="submit"
                                isLoading={isLoading}
                                disabled={checkLastKey()}
                                value="Next"
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </div>
                
    </div>
  );

}
