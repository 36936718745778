import config from "../../config";
// link: config.redesign.REACT_PUBLIC_API_URI + "/about",

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

export const helpfulLinks = [
  {
    title: "about",
    link: config.redesign.REACT_PUBLIC_API_URI + "about",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "faq",
    link: config.redesign.REACT_PUBLIC_API_URI + "faq",
    style: "d-flex text-decoration-none text-uppercase justify-content-center",
  },
  {
    title: "contact us",
    link: config.redesign.REACT_PUBLIC_API_URI + "contact",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "donate",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=billing@sofwerx.org&lc=US&item_name=Doolittle+Institute/SOFWERX+office+OpenWERX&no_note=0&cn=&curency_code=USD&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHosted",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
    target: "_blank",
  },
];
export const common = [
  {
    title: "events",
    link: config.redesign.REACT_PUBLIC_API_EVENTS,
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "tech tuesday",
    link: config.redesign.REACT_PUBLIC_API_URI + "tech-tuesday",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "foundry",
    link: config.redesign.REACT_PUBLIC_API_URI + "foundry",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "projects",
    link: "/",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
];

export const others = [
  {
    title: "Request Forms",
    link: config.redesign.REACT_PUBLIC_API_URI + "request",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "Legal",
    link: config.redesign.REACT_PUBLIC_API_URI + "legal/privacy-policy",
    style:
      " d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "metrics",
    link: config.redesign.REACT_PUBLIC_API_URI + "impact",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
  {
    title: "gear",
    link: "https://sofwerxswag.square.site/",
    style: "d-flex text-decoration-none text-capitalize justify-content-center",
  },
];

export const socialMedia = [
  {
    title: "Facebook",
    link: "https://www.facebook.com/sofwerx/",
    icon: faFacebook,
    target: "_blank",
  },
  {
    title: "Instagram",
    link: "https://www.instagram.com/sofwerx/",
    icon: faInstagram,
    target: "_blank",
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/company/sofwerx/",
    icon: faLinkedin,
    target: "_blank",
  },
  {
    title: "Twitter",
    link: "https://twitter.com/sofwerx/",
    icon: faXTwitter,
    target: "_blank",
  },
];