import React, {useEffect, useState} from "react";
import { useAppContext } from "../libs/contextLib";
import { Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import Popup from "../components/Popup";
import {onError} from "../libs/errorLib";
import Carousel from '../components/FadeCarousel';

export default function NotLoggedIn() {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://techtuesday.sched.com/js/embed.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

        return(
            <div>
                <div className="wrapper wrapper_midLimit">
                    <h2 className="hdg hdg_2">SOFWERX Tech Tuesday</h2>
                    <div>
                        <div>
                            <strong>Who: </strong>
                            <strong className="ATTN">GOVERNMENT ONLY</strong>
                        </div>
                        <div>
                            <strong>What: </strong>
                            <span>Unique capabilities will be pitched in 30-minute time slots to USSOCOM and/or other interested Government partners.</span>
                        </div>
                        <div>
                            <strong>When: </strong>
                            <span>Tuesdays from 1500-1700 EST.</span>
                        </div>
                        <div>
                            <strong>Where: </strong>
                            <span>All presentations are done virtually with dial-in information provided below.</span>
                        </div>
                        <div>
                            <strong>How: </strong>
                            <p>Step 1: Click "<a href="https://techtuesday.sched.com/signup">Sign up</a>" to create a Sched account.</p>
                            <p>Step 2: Select 30-minute capability presentations you plan to attend.</p>
                            <ul>
                                <li>
                                    <span>Your custom schedule will save automatically.</span>
                                </li>
                                <li>
                                    <span>Select presentations you plan to attend by clicking the circle to the left of the company's name.</span>
                                    <ul>
                                        <li>
                                            <em>NOTE: If you don't see the circle, check to make sure you're logged in.</em>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div>
                                <p>Step 3: Review your custom schedule by clicking the black box, at the bottom of the page, labeled "Saved to my Schedule," to view, email, and print your custom schedule.</p>
                            </div>
                            <div>
                                <strong>REMINDER: This custom schedule builder is for Government employees only. If you are not a Government Employee and create a schedule, your access will be removed.</strong>
                            </div>
                            <p>
                                <strong className="txt_callOut">Event Password: tipofthespear (password is subject to change)</strong>
                            </p>
                            <p>If you are having trouble loading the schedule below, please access via the direct link: <a href="https://techtuesday.sched.com/" target="_blank" rel="noreferrer">Click Here to Access</a></p>
                        </div>
                    </div>
                    <div className="iframe">
                    <a id="sched-embed" href="https://techtuesday.sched.com/" data-sched-bg="dark">View the Tech Tuesday schedule &amp; directory.</a>
                    </div>
                </div>
            </div>
        )
}
