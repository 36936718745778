import React from "react";
import { ReactComponent as SWXLogo } from "../assets/images/svg/inline-sofwerx_linear.svg";
import Navbar from "react-bootstrap/Navbar";
import UserMenu from "../components/UserMenu";

const MainNav = () => (
  <div>
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className="mainNav sticky-top"
    >
      <div className="mainNav-wrap">
        <a
          href="https://sofwerx.org"
          className="branding branding_light branding_short"
        >
          <SWXLogo />
        </a>
        <Navbar.Toggle className="mainNav-wrap-toggle" />
        <Navbar.Collapse className="mainNav-wrap-menu justify-content-end">
          <div className="menu">
            <div className="menu-item">
              <a className="menu-item-link" href="https://sofwerx.org/">
                Home
              </a>
            </div>
            <div className="menu-item">
              <a className="menu-item-link" href="https://events.sofwerx.org/">
                Events
              </a>
              <div className="subMenu">
                <div className="subMenu-item">
                  <a href="https://events.sofwerx.org/">Current Events</a>
                </div>
                <div className="subMenu-item">
                  <a href="https://events.sofwerx.org/discover/">Past Events</a>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <a
                className="menu-item-link"
                href="https://sofwerx.org/tech-tuesday/"
              >
                Tech Tuesday
              </a>
            </div>
            <div className="menu-item">
              <a className="menu-item-link" href="https://sofwerx.org/foundry/">
                Foundry
              </a>
            </div>

            <div className="menu-item">
              <span className="menu-item-link mix-menu-item-link_noLink">
                CONNECT
              </span>
              <div className="subMenu">
                <div className="subMenu-item">
                  <a href="https://sofwerx.org/ecosystem/">Join Ecosystem</a>
                </div>
                <div className="subMenu-item">
                  <a href="https://sofwerx.org/tour-request/">Request Tour</a>
                </div>
                <div className="subMenu-item">
                  <a href="https://sofwerxswag.square.site/">Gear</a>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <a className="menu-item-link" href="https://sofwerx.org/impact">
                Metrics
              </a>
              <div className="subMenu">
                <div className="subMenu-item">
                  <a href="https://www.sofwerx.org/impact#ecosystem">
                    Ecosystem
                  </a>
                </div>
                <div className="subMenu-item">
                  <a href="https://www.sofwerx.org/impact#tech-tuesday">
                    Tech Tuesday
                  </a>
                </div>
                <div className="subMenu-item">
                  <a href="https://www.sofwerx.org/impact#event-statistics">
                    Event Statistics
                  </a>
                </div>
                <div className="subMenu-item">
                  <a href="https://www.sofwerx.org/impact#small-business-innovation-research">
                    SBIR/SBTR
                  </a>
                </div>
                <div className="subMenu-item">
                  <a href="https://www.sofwerx.org/impact#impact">Impact</a>
                </div>
                <div className="subMenu-item">
                  <a href="https://www.sofwerx.org/impact#projects">Project</a>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <span className="menu-item-link mix-menu-item-link_noLink">
                PROJECTS
              </span>
              <div className="subMenu">
                <div className="subMenu-item">
                  <a href="https://resources.sofwerx.org/current-projects">
                    Current Projects
                  </a>
                </div>
                <div className="subMenu-item">
                  <a href="https://resources.sofwerx.org/past-projects">
                    Past Projects
                  </a>
                </div>
              </div>
            </div>
            <UserMenu />
          </div>
        </Navbar.Collapse>
        {/* <div class="userMenu">
                    <input id="userMenu" type="checkbox" class="dotMenu"/>

                </div> */}
      </div>
    </Navbar>
    {/*<div className="alertMsg">*/}
    {/*    /!** Add closure alert message here to display under main navigation also add to events and projects site *!/*/}
    {/*    <div>*/}
    {/*        <h2 className="hdg hdg_2 mix-hdg_brand">*/}
    {/*            <strong>ALERT: </strong>*/}
    {/*        </h2>*/}
    {/*        <p>SOFWERX facility will be closed through Thursday 09/29/2022 due to Hurricane Ian, please <a href="mailto:frontofhouse@sofwerx.org" class="link link_clean mix-link_wt mix-link_invert"><span>contact us</span></a> with any questions or concerns. Further updates will be posted as they arise.</p>*/}
    {/*        <h3 className="hdg hdg_3 mix-hdg_brand">*/}
    {/*            <strong>Tech Tuesday: </strong>*/}
    {/*        </h3>*/}
    {/*        <p>Tech Tuesday will be canceled this week also due to the hurricane.</p>*/}
    {/*    </div>*/}
    {/*</div>*/}
  </div>
);
export default MainNav;
