import React, {useState} from "react";
import Form from "react-bootstrap/Form";
// import { useHistory } from "react-router-dom";
// import LoaderButton from "../components/LoaderButton";
// import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
// import { onError } from "../libs/errorLib";
// import { API } from "aws-amplify";
// import { LinkContainer } from "react-router-bootstrap";
// import Button from "react-bootstrap/Button";
// import "../sass/containers/Profile.scss"
import {Auth} from "aws-amplify";
import {onError} from "../libs/errorLib";

export default function RecoverPassword() {

    // const history = useHistory();
    // const { userHasAuthenticated } = useAppContext();
    const titleText = 'Recover Password';
    const resetText = 'Please input your username and click the "Send Recovery Code" button, if the username or email exists in our system an email with a verification code will be sent to the email associated with your account. (Your email may filter these automatic emails so please check your spam or junk mail for the email) If you do not have a user account yet please follow the registration form link in order to set up a user account.';
    const successText = 'Password has been reset. Click below to login.';
    const [passwordRecovered, setPasswordRecovered] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        username: "",
        code: "",
        newPassword: ""
    });

    function validateForm() {
        return fields.username.length > 0
            && fields.newPassword.length > 0 &&
            fields.code.length > 0
    }

    async function handle_submit(event){
        event.preventDefault();
        if(!validateForm())
        {
            onError("Fields cannot be empty")
            return
        }

        Auth.forgotPasswordSubmit(fields.username, fields.code, fields.newPassword)
            .then(data => setPasswordRecovered(true))
            .catch(err => onError(err));
    }

    async function send_code(event){
        event.preventDefault();
        if(fields.username.length <= 0)
        {
            onError("Fields cannot be empty")
            return
        }

        // Send confirmation code to user's email
        Auth.forgotPassword(fields.username)
            .then(data => setCodeSent(true) )
            .catch(err => onError(err));
    }

    function renderRecoverPassword() {
        return (

            <div className="contentBlock-bd contentBlock-bd_push">
                <p>Please input your username and click the "Send Recovery Code" button, if the username or email exists in our system an email with a verification code will be sent to the email associated with your account.</p>
                <p><strong>(Your email may filter these automatically emails so please check your spam or junk mail for the email)</strong></p>
                <p>If you do not have a user account yet please follow the <a href="/membership-registration"><span>register</span></a> link in order to set up a user account.</p>
                <Form >
                    <Form.Group controlId="username" size="lg">
                        <Form.Label>User Name or Email</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.username}
                            onChange={handleFieldChange}
                            hidden={codeSent}
                        />
                    </Form.Group>
                    <Form.Group controlId="newPassword" size="lg" hidden={!codeSent}>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            onChange={handleFieldChange}
                            value={fields.newPassword}
                        />
                    </Form.Group>
                    <Form.Group controlId="code" size="lg" hidden={!codeSent}>
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                            type="text"
                            value={fields.code}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <div className="block-item">
                        <div className="btns" onClick={send_code} hidden={codeSent} >
                            <p>Send Recovery Code</p>
                        </div>
                    </div>
                    <div className="block-item">
                        <div className="btns" onClick={handle_submit} hidden={!codeSent} >
                            <p>Submit</p>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
    function renderSuccessPage() {
        return (
            <div className="contentBlock-bd contentBlock-bd_push">
                <div>{successText} </div>
                <div className="block-item">
                     <div className="btns">
                        <a href="/login">Go to Login Page</a>
                     </div>
                </div>
            </div>
        );
    }
    return (
        <div className="RecoverPassword">
            <div className="wrapper wrapper_midLimit">
                <div className="contentBlock">
                    <div className="contentBlock-hd contentBlock-hd_wordpress">
                        <h2 className="hdg hdg_2">{titleText}</h2>
                    </div>
                    {/*{isAuthenticated ? renderPageOptions() : renderLandingPage()}*/}
                    {passwordRecovered ? renderSuccessPage() : renderRecoverPassword()}
                </div>
            </div>
        </div>
    );
}
