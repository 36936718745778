import React, { useState, useEffect } from "react";
import "./assets/sass/App.scss";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import MainNav from "./components/MainNav";
import SiteFooter from "./components/SiteFooter";
import ReactGA from "react-ga";
import BottomFooter from "./components/Footer/BottomFooter";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const history = useHistory();

  const TRACKING_ID = "UA-180754404-4"; //Google Tracking ID
  ReactGA.initialize("UA-180754404-4", {
    debug: false,
    titleCase: false,
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No user") {
        // onError(e);
        // Popup is causing app to periodically freeze/not get keyboard input
        console.log("No current user");
      }
    }
    setIsAuthenticating(false);
  }
  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }
  return (
    !isAuthenticating && (
      <div style={{ backgroundColor: "#D3D7D6" }}>
        <div className="masthead sticky-top">
          <MainNav />
        </div>
        <div className="content" style={{ backgroundColor: "#D3D7D6" }}>
          <AppContext.Provider
            value={{ isAuthenticated, userHasAuthenticated }}
          >
            <Routes />
          </AppContext.Provider>
        </div>
        <div className="siteFooter">
          <BottomFooter />
        </div>
      </div>
    )
  );
}

export default App;
