import React from "react";
import { Route, Switch } from "react-router-dom";
//import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Registration from "./containers/Registration";
import PastProjects from "./containers/PastProjects";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import CurrentProjects from "./containers/CurrentProjects";
import LandingPage from "./containers/LandingPage"
import Profile from "./containers/Profile"
import EditProfile from "./containers/EditProfile";
import RecoverPassword from "./containers/RecoverPassword";
// import TechNomination from "./containers/TechNomination";
import TechTuesdaySched from "./containers/TechTuesdaySched";

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <LandingPage />
                {/*<CurrentProjects />*/}
            </Route>
            <UnauthenticatedRoute exact path="/login">
                <Login />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/membership-registration">
                <Registration />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/forgot-password">
                <RecoverPassword />
            </UnauthenticatedRoute>
            <Route exact path="/landing-page">
                <LandingPage />
            </Route>
            <AuthenticatedRoute exact path="/past-projects">
                <PastProjects />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/current-projects">
              <CurrentProjects />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/user-profile">
              <Profile />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/edit-profile">
              <EditProfile />
            </AuthenticatedRoute>
            {/* <AuthenticatedRoute exact path="/tech-nomination">
              <TechNomination />
            </AuthenticatedRoute> */}
            <AuthenticatedRoute exact path="/ttsched">
              <TechTuesdaySched />
            </AuthenticatedRoute>

            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}
