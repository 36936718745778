import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
// import { useHistory } from "react-router-dom";
// import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
// import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
// import { LinkContainer } from "react-router-bootstrap";
// import "../sass/containers/Profile.scss"
import ListGroup from "react-bootstrap/ListGroup";
import config from "../config";

export default function Profile() {

    // const history = useHistory();
    const { userHasAuthenticated } = useAppContext();
    const titleText = 'USER CONTENT AREA'
    const [initial, setInitial] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [userAttributes, setUserAttributes] = useState([])

    useEffect(() => {
        async function onLoad() {
            try {
                setInitial(false)
                // const { attributes } = await Auth.currentAuthenticatedUser();

                let attributes = await Auth.currentAuthenticatedUser();
                // console.log(attributes)

                const userInfo = await getUserInformation(attributes['username']);
                // console.log(userInfo)
                setUserAttributes(userInfo)

                setIsLoading(false)
            } catch (e) {
                onError(e);
            }

            setIsLoading(false);
        }
        if (initial)
        {
            onLoad();
        }
        }, [initial]);

    function getUserInformation (username) {
        let path = "/users?userInput=" + username;

        return API.get(config.apiGateway.NAME, path);
    }
    async function Logout () {
        console.log("Logging out");
        await Auth.signOut();
        userHasAuthenticated(false);
    }

    function renderAttributeList(userAttributes) {
        return (
            <>
                <div key={userAttributes.Username} className="cell">
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span>{userAttributes.Username}</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span>{userAttributes.email}</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span>{userAttributes.name}</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span>{userAttributes.family_name}</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span>{userAttributes["custom:organization"]}</span>
                    </div>
                </div>
            </>
        );
    }

    function renderUserAttributes(){
        return (
            <div className="attributes">
                <div className="cell mix-cell_top">
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span className="hdg hdg_3">Username</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span className="hdg hdg_3">Email</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span className="hdg hdg_3">First Name</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span className="hdg hdg_3">Last Name</span>
                    </div>
                    <div className="cell-int cell-int_sm mix-cell-int_label">
                        <span className="hdg hdg_3">Organization</span>
                    </div>
                </div>
                <ListGroup>{!isLoading && renderAttributeList(userAttributes)}</ListGroup>
            </div>
        );
    }

    function renderProfile() {
        return (
            <div>
                <div className="wrapper wrapper_midLimit">
                    <h2 className="hdg hdg_2">{titleText}</h2>
                </div>
                <div className="wrapper wrapper_midLimit">
                    {renderUserAttributes()}
                </div> 
                <div className="wrapper wrapper_small">
                    <div className="block-item">
                        <div className="btns" onClick={Logout} >
                            <p>Log Out</p>
                        </div>
                    </div>
                    {/*<LoaderButton className="btns"*/}
                    {/*    block*/}
                    {/*    size="lg"*/}
                    {/*    type="button"*/}
                    {/*    onClick={Logout}*/}
                    {/*    variant="warning"*/}
                    {/*    // isLoading={isLoading}*/}
                    {/*>*/}
                    {/*    Log Out*/}
                    {/*</LoaderButton>*/}
                    <div className="block-item">
                        <div className="btns">
                            <a href="/edit-profile">Edit Profile</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="Profile">
            {renderProfile()}
        </div>
    );
}
